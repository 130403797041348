import React from "react";

const Terms = () => (
  <div className="white mw8 pv5 ph3 center">
    <a className="f4 white underline pointer " href="/">← Back to home</a>
    <div className="f2 b mt4 mb3">Terms of Service</div>
    <div className="moon-gray lh-copy mb3">
      This site and the mobile applications and services available in connection with this site, mobile application, or Discord channel 
      (the "App") are made available to you by Lofi Labs, LLC. ("Lofi Labs", "StreamKit", "We") subject to these Terms 
      of Service, including those set forth in the Privacy Policy (the “Terms”). By accessing, using or downloading any 
      materials from the App, you agree to follow and be bound by the Terms, which may be updated by us from time to time 
      without notice to you. You can review the most current version of the Terms at any time at Terms of Use. We and our 
      third party providers may make improvements and/or changes in the products, services, mobile applications, features, 
      programs, and prices described in this App at any time without notice. Certain provisions of the Terms may be 
      supplemented or superseded by expressly designated legal notices or terms located on particular pages at this App. 
      The App is not intended for and is not designed to attract children under 13 years of age. If you do not agree with 
      the Terms, please do not use this App. BY CONTINUING TO USE THE APP, YOU ARE INDICATING YOUR AGREEMENT TO THE TERMS 
      AND ALL REVISIONS THEREOF.
    </div>
    <div className="moon-gray lh-copy mb3">
      We reserve the right at any time and from time to time to modify or discontinue, 
      temporarily or permanently, the App or any portion thereof with or without notice. You agree that we shall 
      not be liable to you or to any third party for any modification, suspension or discontinuance of the App or 
      any portion thereof.
    </div>
    <div className="f4 b mb1 mt4">Registration</div>
    <div className="moon-gray lh-copy mb3">
      To use some services available on the App, you must complete the registration and authentication process. You agree to: (a) 
      provide true, accurate, current and complete information about yourself as prompted by the App’s registration  
      (“User Data”) and (b) maintain and promptly update the User Data to keep it accurate and current. You agree that we 
      may use your User Data to provide services on the App for which you have expressed interest. If you provide any information 
      that is inaccurate or not current, or we have reasonable grounds to suspect that such information is inaccurate or not 
      current, we have the right to suspend or terminate your account and refuse any and all current or future use of the App. 
      In consideration of your use of the App, you represent that you are of legal age to form a binding contract and are not 
      a person barred from receiving services under the laws of any applicable jurisdiction.
    </div>
    <div className="f4 b mb1 mt4">Payments</div>
    <div className="moon-gray lh-copy mb3">
      If you choose to buy StreamKit Premium, you must designate and provide information about your preferred payment method (e.g., 
      credit card, online payment service, in-app purchase using your iTunes account, or any other payment method made 
      available by us) (“Payment Method”). You will immediately be charged for these fees after you tap to buy a 
      StreamKit Premium account. You agree to pay all fees incurred in connection with your username and password for your StreamKit account.
    </div>

    <div className="f4 b mb1 mt4">Fees and Renewals</div>
    <div className="moon-gray lh-copy mb3">
      You may elect to buy an upgrade to StreamKit Premium ("upgrade") and pay fees on a annual basis. 
      All membership fees are payable in advance. Membership fees will be billed automatically to the Payment 
      Method at the start of the annual period, as applicable, and will auto-renew until your upgrade is 
      terminated. The renewal fees will be the same as the initial charges unless you are otherwise 
      notified in advance. You authorize us to charge your Payment Method for the appropriate upgrade charges 
      and fees and for any other purchases you elect to make via the App. We reserve the right to increase upgrade 
      fees or to institute new fees at any time upon reasonable notice posted in advance on this App. If you 
      upgrade or add new categories of service to your account, such changes may result in a new 
      billing date effective upon the date you elect such upgrade or addition. ALL PURCHASES ARE FINAL AND NO 
      REFUND WILL BE GIVEN FOR UNUSED PORTIONS OF YOUR INITIAL OR ANY MEMBERSHIP PERIOD.
    </div>
    <div className="f4 b mb1 mt4">Free Trial Eligibility</div>
    <div className="moon-gray lh-copy mb3">
      Free trials are limited to one per iTunes account.
    </div>
    <div className="f4 b mb1 mt4">Cancellation</div>
    <div className="moon-gray lh-copy mb3">
      You may cancel your StreamKit Premium upgrade by visiting the subscription management options within iOS. 
      The cancellation of a membership will go into effect at the end of your current billing cycle, and you will 
      have the same level of access to the App through the remainder of such billing cycle. When your upgrade ends, 
      your account will enter free mode. No refunds or credits will be provided by us upon cancellation. You can 
      renew your StreamKit Premium upgrade at anytime without opening a new account, provided that additional fees may apply if 
      you decide to upgrade to a StreamKit Premium at a later date.
    </div>

    <div className="f4 b mb1 mt4">User Conduct</div>
    <div className="moon-gray lh-copy mb3">
      You are solely responsible for maintaining the confidentiality of the password associated with your account 
      and for restricting access to your password and to your computer while logged into the App. You accept 
      responsibility for all activities that occur under your account or from your computer. We endeavor to use 
      reasonable security measures to protect against unauthorized access to your account. We cannot, however, 
      guarantee absolute security of your account, your Content or the personal information you provide, and we 
      cannot promise that our security measures will prevent third-party “hackers” from illegally accessing the 
      App or its contents. You agree to immediately notify us of any unauthorized use or your account or password, 
      or any other breach of security, and to accept all risks of unauthorized access to the User Data and any other 
      information you provide to us.
    </div>
    <div className="moon-gray lh-copy mb3">
      You understand that all information, data, text, software, sound, photographs, graphics, video, messages, tags, 
      or other materials (“Content”), whether publicly posted or privately transmitted, available in connection with 
      the App are the sole responsibility of the person from whom such Content originated. This means that you, and 
      not us, are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available 
      via the App. We do not control or monitor the Content posted to the App by others and, as such, does not guarantee 
      the accuracy, integrity or quality of such Content. You understand that by using the App, you may be exposed to 
      Content that is offensive, indecent or objectionable. Under no circumstances will we be liable in any way for 
      any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of 
      any kind incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available 
      on the App.
    </div>
    <div className="moon-gray lh-copy mb3">
      You agree to not use the App to: (a) upload, post, email, transmit or otherwise make available any Content that is 
      unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of 
      another’s privacy, hateful, or racially, ethnically or otherwise objectionable; (b) harm minors in any way; (c) 
      impersonate any person or entity, including, but not limited to, a Lofi Labs official, forum leader, guide 
      or host, or falsely state or otherwise misrepresent your affiliation with a person or entity; (d) upload, post, 
      email, transmit or otherwise make available any Content that you do not have a right to make available under any 
      law or under contractual or fiduciary relationships; (e) upload, post, email, transmit or otherwise make available 
      any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party; 
      (f) upload, post, email, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional 
      materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation; (g) upload, 
      post, email, transmit or otherwise make available any material that contains software viruses or any other computer 
      code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or 
      hardware or telecommunications equipment; (h) interfere with or disrupt the App or servers or networks connected 
      to the App, or disobey any requirements, procedures, policies or regulations of networks connected to the App; 
      and/or (i) intentionally or unintentionally violate any applicable local, state, national or international law. 
      You specifically agree not to access (or attempt to access) the App or the Content through any automated means 
      (including the use of any script, web crawler, robot, spider, or scraper), and that you will not forge or 
      manipulate identifiers in order to disguise the origin of any access (or attempted access) to the App.
    </div>
    <div className="moon-gray lh-copy mb3">
      You acknowledge that we may or may not pre-screen or monitor Content, but that we and out designees shall have the 
      right (but not the obligation) in their sole discretion to pre-screen, monitor, refuse or remove any Content that 
      is available via the App. Without limiting the foregoing, we and our designees shall have the right to remove any 
      Content that violates the Terms or is otherwise objectionable. You agree that you must evaluate, and bear all risks 
      associated with, the use of any Content available in connection with the App, including any reliance on the accuracy, 
      completeness, or usefulness of such Content.
    </div>

    <div className="moon-gray lh-copy mb3">
      You acknowledge, consent and agree that we may access, preserve and disclose your User Data, Payment Method information 
      and other Content if required to do so by law or in a good faith belief that such access preservation or disclosure is 
      reasonably necessary to: (a) comply with legal process; (b) enforce the Terms; (c) respond to claims that any Content 
      violates the rights of third parties; (d) respond to your requests for customer service; or (e) protect the rights, 
      property or personal safety of us, its users and the public. Subject to the foregoing, we will use reasonable efforts 
      to maintain the confidentiality of your User Data and Payment Method information.
    </div>

    <div className="moon-gray lh-copy mb3">
      The App is for your personal and noncommercial use. You may not modify, copy, distribute, transmit, display, perform, 
      reproduce, publish, license, create derivative works from, transfer or sell for any commercial purposes any portion of 
      the App, use of the App or access to the App.
    </div>
    <div className="moon-gray lh-copy mb3">
      You understand that use of certain features of the App may require you to purchase third party equipment or materials. 
      While we may recommend the equipment or materials of certain third party suppliers, we shall have no responsibility for 
      your acquisition or use of any third party equipment or materials and does not guarantee that third party equipment or 
      materials will function with the App or will be error-free.
    </div>
    <div className="f4 b mb1 mt4">Content Submitted to the App</div>
    <div className="moon-gray lh-copy mb3">
      We do not claim ownership of Content you may submit or make available for inclusion on the App. However, with respect 
      to Content you submit or make available for inclusion on the App (other than User Data or Payment Method information), 
      you grant us a worldwide, perpetual, irrevocable, royalty-free and non-exclusive license, as applicable, to use, distribute, 
      reproduce, modify, adapt, publicly perform and publicly display and otherwise exploit such Content on the App for the 
      purposes of providing and promoting the App and the features and services available on the App.
     </div>

    <div className="f4 b mb1 mt4">Electronic Communications</div>
    <div className="moon-gray lh-copy mb3">
      The App may provide you with the ability to send e-mails, post messages to user forums, enter chat rooms, speak via 
      Internet voice connections or send similar messages and communications to third party service providers, advertisers, 
      other users and/or us. You agree to use communication methods available on the App only to send communications and 
      materials related to the subject matter for which we provided the communication method, and you further agree that 
      all such communications by you shall be deemed your Content and shall be subject to and governed by the Terms. By 
      using any of the communications methods available on the App, you acknowledge and agree that (a) all communications 
      methods constitute public, and not private, means of communication between you and the other party or parties, (b) 
      communications sent to or received from third party service providers, advertisers or other third parties are not 
      be endorsed, sponsored or approved by us (unless expressly stated otherwise by Lofi Labs) and (c) communications 
      are not pre-reviewed, post-reviewed, screened, archived or otherwise monitored by us in any manner, though we reserve 
      the right to do so at any time at its sole discretion in accordance with the Terms. You agree that all notices, 
      disclosures and other communications that we provide to you electronically shall satisfy any legal requirement that 
      such communications be in writing.
    </div>
    <div className="f4 b mb1 mt4">Electronic Communications</div>
    <div className="moon-gray lh-copy mb3">
      You acknowledge and agree that the App, any necessary software used in connection with the App (if any) 
      and any Content available on the App contain proprietary and confidential information that is protected 
      by applicable intellectual property and other laws. Except as expressly permitted by applicable law or 
      authorized by us or applicable third party service providers or advertisers, you agree not to modify, 
      rent, lease, loan, sell, distribute or create derivative works based on the App, the software or Content 
      available on the App (other than Content that you may submit), in whole or in part.
     </div>
     <div className="moon-gray lh-copy mb3">
      We grant you a personal, non-transferable and non-exclusive right and license to access and use the App; 
      provided that you do not (and do not allow any third party to) copy, modify, create a derivative work 
      from, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, 
      sublicense, grant a security interest in or otherwise transfer any right in the App. You agree not to 
      access the App by any means other than through the interface that is provided by us for use in accessing the App.
     </div>
     <div className="moon-gray lh-copy mb3">
      The term StreamKit, the StreamKit logo and other Lofi Labs logos and product and service names are the exclusive 
      trademarks of, and are owned by, Lofi Labs, LLC., and you may not use or display such trademarks in any 
      manner without our prior written permission. Any third party trademarks or service marks displayed on the App are 
      the property of their respective owners.
     </div>
     <div className="moon-gray lh-copy mb3">
       We reserve all rights not expressly granted hereunder.
     </div>
    <div className="f4 b mb1 mt4">Disclaimer of Warranties and Liability</div>
    <div className="moon-gray lh-copy mb3">
      THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND CONTENT AVAILABLE ON THE APP IS PROVIDED TO YOU “AS IS” AND 
      WITHOUT WARRANTY. LOFI LABS AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND 
      LICENSORS HEREBY DISCLAIM ALL WARRANTIES WITH REGARD TO SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND CONTENT, 
      INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR 
      PURPOSE, TITLE AND NONINFRINGEMENT. LOFI LABS AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, 
      PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (a) THE SERVICE WILL MEET YOUR REQUIREMENTS; (b) THE SERVICE WILL BE 
      UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL 
      BE ACCURATE OR RELIABLE; (d) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED 
      BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS; AND (e) ANY ERRORS IN THE APP WILL BE CORRECTED.
    </div>
    <div className="moon-gray lh-copy mb3">
      YOU EXPRESSLY AGREE TO RELEASE LOFI LABS, ITS SUBSIDIARIES, AFFILIATES, OFFICERS, AGENTS, REPRESENTATIVES, 
      EMPLOYEES, PARTNERS AND LICENSORS (THE “RELEASED PARTIES”) FROM ANY AND ALL LIABILITY CONNECTED WITH YOUR BROADCAST ACTIVITIES, 
      AND PROMISE NOT TO SUE THE RELEASED PARTIES FOR ANY CLAIMS, ACTIONS, INJURIES, DAMAGES, OR LOSSES 
      ASSOCIATED WITH YOUR BROADCAST ACTIVITIES. YOU ALSO AGREE THAT IN NO EVENT SHALL THE RELEASED PARTIES BE LIABLE 
      TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING 
      OUT OF OR IN ANY WAY CONNECTED WITH (a) YOUR USE OR MISUSE OF THE APP, (b) YOUR USE OR MISUSE OF EQUIPMENT OR 
      PROGRAMS CREATED OR LICENSED BY LOFI LABS WHILE ENGAGED IN BROADCAST ACTIVITIES, (c) YOUR DEALINGS WITH 
      THIRD PARTY SERVICE PROVIDERS OR ADVERTISERS AVAILABLE THROUGH THE APP, (d) ANY DELAY OR INABILITY TO USE THE 
      APP EXPERIENCED BY YOU, (e) ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES OR CONTENT OBTAINED THROUGH THE APP, 
      WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF LOFI LABS HAS BEEN ADVISED OF THE 
      POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY 
      FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
    </div>
    <div className="f4 b mb1 mt4">Indemnity</div>
    <div className="moon-gray lh-copy mb3">
      You agree to indemnify and hold us and our subsidiaries, affiliates, officers, agents, representatives, 
      employees, partners and licensors harmless from any claim or demand, including reasonable attorneys’ 
      fees, made by any third party due to or arising out of Content you submit, post, transmit or otherwise 
      seek to make available through the App, your use of the App, your broadcast activities which generate 
      the Content you post or seek to post on the App, your connection to the App, your violation 
      of the Terms, or your violation of any rights of another person or entity.
    </div>
    <div className="f4 b mb1 mt4">Termination</div>
    <div className="moon-gray lh-copy mb3">
      You agree that we may, under certain circumstances and without prior notice, immediately terminate your 
      account and/or access to the App. Cause for such termination shall include, but not be limited to, (a) 
      breaches or violations of the Terms or other incorporated agreements or guidelines, (b) requests by law 
      enforcement or other government agencies, (c) a request by you (self-initiated account deletions), (d) 
      discontinuance or material modification to the App (or any portion thereof), (e) unexpected technical 
      or security issues or problems, (f) extended periods of inactivity, and/or (g) nonpayment of any fees 
      owed by you in connection with the App. Termination of your account may include (x) removal of access 
      to all offerings within the App, (y) deletion of your information, files and Content associated with 
      or inside your account, and (z) barring of further use of the App. Further, you agree that all terminations 
      for cause shall be made in our sole discretion and that we shall not be liable to you or any third party 
      for any termination of your account or access to the App.
    </div>
    <div className="f4 b mb1 mt4">APPLICABLE LAWS</div>
    <div className="moon-gray lh-copy mb3">
      This App is controlled by Lofi Labs from its offices within the United States of America. We make no representation 
      that the Content in the App or the App are appropriate or available for use in other locations, and access 
      to them from territories where their content or use is illegal is prohibited. Those who choose to access 
      this App from locations outside of the United States do so on their own initiative and are responsible for 
      compliance with applicable local laws. You may not use or export the Content in violation of U.S. export 
      laws and regulations. Any action related to the App, the Content or the Terms shall be governed by 
      Pennsylvania law and controlling U.S. federal law, without regard to conflicts of laws thereof. You 
      hereby consent and submit to the exclusive jurisdiction and venue in the state and federal courts located 
      in Philadelphia County, Pennsylvania for any legal proceedings related to the App or the Terms. If 
      any party hereto brings any suit or action against another for relief, declaratory or otherwise, arising 
      out of these Terms, the prevailing party will have and recover against the other party, in addition to 
      all court costs and disbursements, such sum as the court may adjudge to be reasonable attorneys’ fees. 
      Except to the extent prohibited by applicable law, the parties agree that any claim or cause of action 
      arising out of or related to use of the App or the Terms must be filed within one (1) year after such 
      claim or cause of action arose or be forever barred.
    </div>
    <div className="f4 b mb1 mt4">APPLICABLE LAWS</div>
    <div className="moon-gray lh-copy mb3">
      You agree that no joint venture, partnership, employment or agency relationship exists between you and 
      Lofi Labs as a result of the Terms or your use of the App. The Terms constitute the entire agreement 
      between you and Lofi Labs with respect to your use of the App. The failure of us to exercise or 
      enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If 
      any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties 
      nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected 
      in the provision, and the other provisions of the Terms remain in full force and effect. You may not assign, 
      delegate or otherwise transfer your account or your obligations under these Terms without the prior written 
      consent of us. We have the right, in its sole discretion, to transfer or assign all or any part of its 
      rights under these Terms and will have the right to delegate or use third party contractors to fulfill 
      its duties and obligations under these Terms and in connection with the App. Our notice to you via email, 
      regular mail or notices or links on the App shall constitute acceptable notice to you under the Terms. 
      A printed version of the Terms and of any notice given in electronic form shall be admissible in judicial 
      or administrative proceedings based upon or relating to the Terms to the same extent and subject to the 
      same conditions as other business documents and records originally generated and maintained in printed form. 
      Section titles and headings in the Terms are for convenience only and have no legal or contractual effect. 
      Any rights not expressly granted herein are reserved.
    </div>
    <div className="moon-gray lh-copy mb3">
      <div className="mb1">We will provide support to you related to the App via the following methods:</div>
      <div>Website - https://getstreamkit.com</div>
      <div className="mb3">Email - getstreamkit@gmail.com</div>
      <div>Lofi Labs strives to respond to support requests within 2 business days after the request is placed.</div>
    </div>
    <div className="moon-gray lh-copy mb3">
      If you have any questions regarding these Terms, please contact us by email at getstreamkit@gmail.com.
    </div>
  </div>
)

export default Terms;
